import { useEffect, useState } from 'react';

import CadastroObras from 'components/Obras/CadastroObras';
import { Box, Button, Modal } from '@mui/material';
import { IconX } from '@tabler/icons';
import CadastroObrasModal from '../CadastroObras';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '90%',
    overflow: 'auto',
    borderRadius: '5px'
};

function CreateContructionModal({ setListAllObras }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const api = useApi();

    const getConstruction = async () => {
        try {
            const { data } = await api.getAllObra();
            setListAllObras(data?.obras);
            toast.success('Obra cadastrada com sucesso!');
        } catch (error) {
            console.log('🚀 ~ getConstruction ~ error:', error);
        }
    };

    return (
        <div>
            <Button onClick={handleOpen}>Adicionar Obra</Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <div style={{ textAlign: 'center' }}>
                        <h2 style={{ textAlign: 'center' }}>Inserir nova obra</h2>
                        <IconX onClick={handleClose} style={{ position: 'absolute', top: '20px', right: '20px' }} />
                    </div>
                    <CadastroObrasModal getConstruction={getConstruction} />
                </Box>
            </Modal>
        </div>
    );
}

export default CreateContructionModal;
