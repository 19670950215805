import axios from 'axios';

const api = axios.create({
    // --- AZURE ---
    //baseURL: 'https://backendbluvale.gentlesea-52860fcf.eastus.azurecontainerapps.io'
    // --- RAILWAY ---
    baseURL: 'https://backendbluvale-production.up.railway.app'
    // --- LOCALHOST ---
    // baseURL: 'http://localhost:8080'
});
// deploy 03-12-2014 v1
export const useApi = () => ({
    //singin and singout
    signin: async (usuario, password) => {
        try {
            const response = await api.post('/users/login', { username: usuario, senha: password });
            return response;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error(error.message);
            }
        }
    },
    getAllUsers: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/users', { headers });
        return response;
    },
    getUserById: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/users/${id}`, { headers });
        return response;
    },
    createUser: async (values) => {
        const response = await api.post('/users', {
            nome_completo: values.nome,
            username: values.usuario,
            email: values.email,
            setor: values.setor,
            telefone: values.telefone,
            senha: values.password
        });
        return response;
    },
    updateUser: async (values, id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/users/${id}`,
            {
                username: values.username,
                nome_completo: values.nome_completo,
                email: values.email,
                telefone: values.phone,
                setor: values.sector,
                password: values.password
            },
            { headers }
        );

        return response;
    },
    deleteUser: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/users/${id}`, { headers });
        return response;
    },

    // ======== categoria de item ========

    createCategory: async (values) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/categorias',
            {
                descricao: values.descricao.toUpperCase()
            },
            { headers }
        );
        return response;
    },
    getAllCategory: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/categorias', { headers });
        return response;
    },
    getCategoryById: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/categorias/${id}`, { headers });
        return response;
    },
    updateCategory: async (id, values) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/categorias/${id}`,
            {
                descricao: values.descricao.toUpperCase()
            },
            { headers }
        );
        return response;
    },
    deleteCategory: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/categorias/${id}`, { headers });
        return response;
    },

    // ======== unidade de medida ========
    createUnit: async (values) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/unidades',
            {
                descricao: values.descricao.toUpperCase(),
                unidade_medida: values.unidade.toUpperCase()
            },
            { headers }
        );
        return response;
    },
    updateUnit: async (id, values) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `unidades/${id}`,
            {
                descricao: values.descricao.toUpperCase(),
                unidade_medida: values.unidade_medida.toUpperCase()
            },
            { headers }
        );
    },
    getAllUnit: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/unidades', { headers });
        return response;
    },
    getUnitById: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/unidades/${id}`, { headers });
        return response;
    },
    deleteUnit: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/unidades/${id}`, { headers });
        return response;
    },

    // ======== almoxarifado ========
    createAlmoxarifado: async (values) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post('/almoxarifado', { descricao: values.descricao.toUpperCase() }, { headers });
        return response;
    },
    getAllAlmoxarifado: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/almoxarifado', { headers });
        return response;
    },
    getAlmoxarifadoById: async (id) => {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const response = await api.get(`/almoxarifado/${id}`, { headers });
        return response;
    },
    updateAlmoxarifado: async (id, values) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/almoxarifado/${id}`, { descricao: values.descricao.toUpperCase() }, { headers });
        return response;
    },
    deleteAlmoxarifado: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/almoxarifado/${id}`, { headers });
        return response;
    },
    // ======== localização ========
    createLocalizacao: async (values) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/localizacao',
            { descricao: values.descricao.toUpperCase(), id_almoxarifado: values.id_almoxarifado },
            { headers }
        );
        return response;
    },
    getAllLocalizacao: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/localizacao', { headers });
        return response;
    },
    getLocalizacaoById: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/localizacao/${id}`, { headers });
        return response;
    },
    updateLocalizacao: async (id, values) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/localizacao/${id}`,
            { descricao: values.descricao.toUpperCase(), id_almoxarifado: values.id_almoxarifado },
            { headers }
        );
        return response;
    },
    deleteLocalizacao: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/localizacao/${id}`, { headers });
        return response;
    },
    // ======== Item ========
    createItem: async (values) => {
        const token = localStorage.getItem('token');

        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/itens',
            {
                descricao: values.descricao.toUpperCase(),
                apelido: values.apelido && values.apelido.toUpperCase(),
                unidade_medida_id: values.unidade_medida_id,
                categoria_id: values.categoria_id && values.categoria_id !== null && parseFloat(values.categoria_id),
                quantidade_minima: values.quantidade_minima && values.quantidade_minima !== null && parseFloat(values.quantidade_minima),
                lote_economico: values.lote_economico && values.lote_economico !== null && parseFloat(values.lote_economico),
                almoxarifado_id: values.almoxarifado_id && values.almoxarifado_id !== null && parseFloat(values.almoxarifado_id),
                localizacao_id: values.localizacao_id && values.localizacao_id !== null && parseFloat(values.localizacao_id)
            },
            { headers }
        );
        return response;
    },
    getAllItens: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/itens', { headers });
        return response;
    },
    getItemById: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/itens/${id}`, { headers });
        return response;
    },
    updateItem: async (id, values) => {
        console.log('AXIOS', values);
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/itens/${id}`,
            {
                descricao: values?.descricao && values?.descricao.toUpperCase(),
                apelido: values?.apelido && values?.apelido.toUpperCase(),
                unidade_medida_id: values?.unidade_medida_id && values?.unidade_medida_id,
                categoria_id: values?.categoria_id && values?.categoria_id !== null && parseFloat(values.categoria_id),
                quantidade_minima: values?.quantidade_minima && values?.quantidade_minima !== null && parseFloat(values.quantidade_minima),
                lote_economico: values?.lote_economico && values?.lote_economico !== null && parseFloat(values.lote_economico),
                almoxarifado_id: values?.almoxarifado_id && values?.almoxarifado_id !== null && parseFloat(values.almoxarifado_id),
                localizacao_id: values?.localizacao_id && values?.localizacao_id !== null && parseFloat(values.localizacao_id),
                ativo: values?.ativo
            },
            { headers }
        );
        return response;
    },
    deleteItem: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/itens/${id}`, { headers });
        return response;
    },
    // ======== CEP ========
    // API DE CEP
    getCepLocation: async (cod) => {
        const response = await api.get(`https://viacep.com.br/ws/${cod}/json/`);
        return response;
    },
    // API CODIGO IBGE
    getIbgeLocation: async (cod) => {
        const response = await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${cod}/distritos`);
        return response;
    },
    // ======== OBRAS ========
    createObra: async (values) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/obras',
            {
                descricao: values.descricao && values.descricao.toUpperCase(),
                cep: values.cep && values.cep,
                endereco: values.rua && values.rua.toUpperCase(),
                numero: values.numero,
                bairro: values.bairro && values.bairro.toUpperCase(),
                estado: values.estado && values.estado.toUpperCase(),
                cidade: values.cidade && values.cidade.toUpperCase(),
                tipo_logradouro: values.tipo_logradouro && values.tipo_logradouro.toUpperCase(),
                complemento: values.complemento && values.complemento.toUpperCase(),
                contato: values.contato,
                telefone: values.telefone
            },
            { headers }
        );
        return response;
    },
    getAllObra: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/obras', { headers });
        return response;
    },
    getObraById: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/obras/${id}`, { headers });
        return response;
    },
    updateObra: async (id, values) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/obras/${id}`,
            {
                descricao: values.descricao && values.descricao.toUpperCase(),
                cep: values.cep && values.cep,
                endereco: values.rua && values.rua.toUpperCase(),
                numero: values.numero,
                bairro: values.bairro && values.bairro.toUpperCase(),
                estado: values.estado && values.estado.toUpperCase(),
                cidade: values.cidade && values.cidade.toUpperCase(),
                tipo_logradouro: values.tipo_logradouro && values.tipo_logradouro.toUpperCase(),
                complemento: values.complemento && values.complemento.toUpperCase(),
                contato: values.contato,
                telefone: values.telefone
            },
            { headers }
        );
        return response;
    },
    deleteObra: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/obras/${id}`, { headers });
        return response;
    },

    // ======== PROJETOS ========

    createProjeto: async (values) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            `/projetos`,
            {
                descricao: values.descricao.toUpperCase(),
                obra_id: values.obra_id,
                responsavel: values.responsavel.toUpperCase()
            },
            { headers }
        );
        return response;
    },
    getAllProjeto: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/projetos', { headers });
        return response;
    },
    getProjetoById: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/projetos/${id}`, { headers });
        return response;
    },
    updateProjeto: async (id, values) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/projetos/${id}`,
            {
                descricao: values.descricao.toUpperCase(),
                obra_id: values.obra_id,
                responsavel: values.responsavel.toUpperCase()
            },
            { headers }
        );
        return response;
    },
    deleteProjeto: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/projetos/${id}`, { headers });
        return response;
    },

    // ======== CENTRO DE CUSTO ========

    createCentroCusto: async (values) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/centro-custo',
            {
                descricao: values.descricao.toUpperCase()
            },
            { headers }
        );
        return response;
    },

    getAllCentroCusto: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/centro-custo', { headers });
        return response;
    },

    getCentroCustoById: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/centro-custo/${id}`, { headers });
        return response;
    },

    deleteCentroCusto: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/centro-custo/${id}`, { headers });

        return response;
    },

    editCentroCusto: async (id, values) => {
        const token = localStorage.getItem('token');

        const headers = {
            Authorization: `Bearer ${token}`
        };

        const response = await api.put(
            `/centro-custo/${id}`,
            {
                descricao: values.descricao.toUpperCase()
            },
            { headers }
        );

        return response;
    },
    //==== FORNECEDOR ====
    createFornecedor: async (values) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/fornecedores',
            {
                cnpj: values.cnpj,
                nome_fantasia: values.nome_fantasia.toUpperCase(),
                email: values.email.toUpperCase(),
                contato: values.contato.toUpperCase(),
                telefone_comercial: values.telefone_comercial,
                telefone_celular: values.telefone_celular,
                cep: values.cep,
                endereco: values.endereco.toUpperCase(),
                numero: values.numero,
                complemento: values.complemento.toUpperCase(),
                bairro: values.bairro.toUpperCase(),
                cidade: values.cidade.toUpperCase(),
                estado: values.estado.toUpperCase(),
                observacao: values.observacao.toUpperCase(),
                status: values.ativo
            },
            { headers }
        );
        return response;
    },
    getAllFornecedor: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/fornecedores', { headers });
        return response;
    },
    getFornecedorById: async (fornecedorId) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/fornecedores/${fornecedorId}`, { headers });
        return response;
    },
    updateFornecedor: async (fornecedorId, values) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/fornecedores/${fornecedorId}`,
            {
                cnpj: values.cnpj,
                nome_fantasia: values.nome_fantasia.toUpperCase(),
                email: values.email.toUpperCase(),
                contato: values.contato.toUpperCase(),
                telefone_comercial: values.telefone_comercial,
                telefone_celular: values.telefone_celular,
                cep: values.cep,
                endereco: values.endereco.toUpperCase(),
                numero: values.numero,
                complemento: values.complemento.toUpperCase(),
                bairro: values.bairro.toUpperCase(),
                cidade: values.cidade.toUpperCase(),
                estado: values.estado.toUpperCase(),
                observacao: values.observacao.toUpperCase(),
                status: values.ativo
            },
            { headers }
        );
        return response;
    },
    deleteFornecedor: async (fornecedorId) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/fornecedores/${fornecedorId}`, { headers });
        return response;
    },
    ////// ENTRADAS ////////
    createEntradaEstoque: async (entrada, itensEntrada) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const usuario = localStorage.getItem('id');
        const response = await api.post(
            '/entradas',
            {
                numero_documento: entrada.documento.toUpperCase(),
                data_emissao: entrada.emissao,
                data_entrada: entrada.movimento,
                observacao: entrada.observacao.toUpperCase(),
                fornecedor_id: entrada.fornecedor,
                itens: itensEntrada,
                responsavel_id: usuario
            },
            { headers }
        );
        return response;
    },
    getAllEntradasEstoques: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/entradas', { headers });
        return response;
    },
    getEntradaEstoqueById: async (entradaId) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/entradas/${entradaId}/itens`, { headers });
        return response;
    },
    updateEntradaEstoque: async (entradaId, entrada, itensEntrada) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const usuario = localStorage.getItem('id');
        const response = await api.put(
            `/entradas/${entradaId}`,
            {
                numero_documento: entrada.documento.toUpperCase(),
                data_emissao: entrada.emissao,
                data_entrada: entrada.movimento,
                observacao: entrada.observacao.toUpperCase(),
                fornecedor_id: entrada.fornecedor,
                itens: itensEntrada,
                responsavel_id: usuario
            },
            { headers }
        );
        return response;
    },
    deleteEntradaEstoque: async (entradaId) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/entradas/${entradaId}`, { headers });
        return response;
    },
    ///// SAIDAS /////
    createSaida: async (values) => {
        const token = localStorage.getItem('token');
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/saidas',
            {
                item_id: values.itemId,
                unidade_id: values.unidade,
                quantidade: Number(values.quantidade),
                data: values.data,
                almoxarifado_id: values.almoxarifado,
                localizacao_id: values.localizacao,
                observacao: values.observacao,
                responsavel_id: usuario
            },
            { headers }
        );
        return response;
    },
    updateSaida: async (id, values) => {
        const token = localStorage.getItem('token');
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/saidas/${id}`,
            {
                item_id: values.itemId,
                unidade_id: values.unidade,
                quantidade: Number(values.quantidade),
                data: values.data,
                almoxarifado_id: values.almoxarifado,
                localizacao_id: values.localizacao,
                observacao: values.observacao,
                responsavel_id: usuario
            },
            { headers }
        );
        return response;
    },
    getAllSaidas: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/saidas', { headers });
        return response;
    },
    getSaidaById: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/saidas/${id}`, { headers });
        return response;
    },
    getSaidaByIdWithItems: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/saidas/${id}/itens`, { headers });
        return response;
    },
    deleteSaida: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/saidas/${id}`, { headers });
        return response;
    },
    createEntradaManual: async (values) => {
        const token = localStorage.getItem('token');
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/entrada-manual',
            {
                item_id: values.itemId,
                unidade_id: values.unidade,
                quantidade: Number(values.quantidade),
                data: values.data,
                almoxarifado_id: values.almoxarifado,
                localizacao_id: values.localizacao,
                observacao: values.observacao,
                responsavel_id: usuario
            },
            { headers }
        );
        return response;
    },
    getAllEntradaManual: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/entrada-manual', { headers });
        return response;
    },
    getEntradaManualWithInclude: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/entrada-manual/${id}/itens`, { headers });
        return response;
    },
    updateEntradaManual: async (id, values) => {
        const token = localStorage.getItem('token');
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/entrada-manual/${id}`,
            {
                item_id: values.itemId,
                unidade_id: values.unidade,
                quantidade: Number(values.quantidade),
                data: values.data,
                almoxarifado_id: values.almoxarifado,
                localizacao_id: values.localizacao,
                observacao: values.observacao,
                responsavel_id: usuario
            },
            { headers }
        );
        return response;
    },
    deleteEntradaManual: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/entrada-manual/${id}`, { headers });
        return response;
    },
    createTransferencia: async (values) => {
        const token = localStorage.getItem('token');
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/transferencia',
            {
                item_id: values.itemId,
                unidade_id: values.unidade,
                data_transferencia: values.data,
                quantidade: Number(values.quantidade),
                origem_almoxarifado_id: values.almoxarifado_origem,
                origem_localizacao_id: values.localizacao_origem,
                destino_almoxarifado_id: values.almoxarifado_destino,
                destino_localizacao_id: values.localizacao_destino,
                observacao: values.observacao,
                responsavel_id: usuario
            },
            { headers }
        );
        return response;
    },
    getAllTransferencia: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/transferencia', { headers });
        return response;
    },
    getTransferenciaById: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/transferencia/${id}`, { headers });
        return response;
    },
    updateTransferencia: async (id, values) => {
        const token = localStorage.getItem('token');
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/transferencia/${id}`,
            {
                item_id: values.itemId,
                unidade_id: values.unidade,
                data_transferencia: values.data,
                quantidade: Number(values.quantidade),
                origem_almoxarifado_id: values.almoxarifado_origem,
                origem_localizacao_id: values.localizacao_origem,
                destino_almoxarifado_id: values.almoxarifado_destino,
                destino_localizacao_id: values.localizacao_destino,
                observacao: values.observacao,
                responsavel_id: usuario
            },
            { headers }
        );
        return response;
    },
    // MOV SUMARIZADO
    getMovSumarizados: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/mov-sumarizado', { headers });
        return response;
    },
    getMovSumarizadoByItemId: async (itemId) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/mov-sumarizado/${itemId}`, { headers });
        return response;
    },
    // KARDEX
    getKardex: async (itemId, dataInicial, dataFinal) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/kardex/${itemId}?dataInicial=${dataInicial}&dataFinal=${dataFinal}`, { headers });
        return response;
    },

    // SOLICITAÇÃO
    createSolicitacao: async (solicitacao, itens) => {
        const token = localStorage.getItem('token');
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            `/solicitacoes/`,
            {
                data: solicitacao.emissao,
                necessidade: solicitacao.necessidade,
                obra_id: solicitacao.obra,
                projeto_id: solicitacao.projeto,
                observacao: solicitacao.observacao,
                solicitante_id: usuario,
                itens: itens
            },
            { headers }
        );
        return response;
    },
    getAllSolicitacao: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/solicitacoes`, { headers });
        return response;
    },
    getSolicitacaoById: async (solicitaacoId) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/solicitacoes/${solicitaacoId}`, { headers });
        return response;
    },
    updateSolicitacao: async (solicitacaoId, solicitacao, itens) => {
        const token = localStorage.getItem('token');
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/solicitacoes/${solicitacaoId}`,
            {
                data: solicitacao.emissao,
                necessidade: solicitacao.necessidade,
                obra_id: solicitacao.obra,
                projeto_id: solicitacao.projeto,
                observacao: solicitacao.observacao,
                solicitante_id: usuario,
                itens: itens
            },
            { headers }
        );
        return response;
    },
    deleteSolicitacao: async (solicitacaoId) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/solicitacoes/${solicitacaoId}`, { headers });
        return response;
    },
    // === SEPARAÇÃO ====
    createSeparacao: async (separacaoData) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const usuario = localStorage.getItem('id');
        const response = await api.post(`/separacao`, { ...separacaoData, solicitante_id: usuario }, { headers });
        return response;
    },
    getSeparacaoById: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/separacao/${id}`, { headers });
        return response;
    },
    // === LIBERAÇÃO ====
    getAllPendingLiberacao: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/liberacao', { headers });
        return response;
    },
    getPedingv2: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/solicitacoes/status/separado', { headers });
        return response;
    },
    createLiberacao: async (liberacaoData) => {
        console.log(liberacaoData, 'liberacaoData');
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const usuario = localStorage.getItem('id');
        const response = await api.post(`/liberacao`, { separacao_id: liberacaoData, solicitante_id: parseInt(usuario) }, { headers });
        return response;
    }, //Separação
    getNv1Separacao: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/separacao/solicitacao/${id}`, { headers });
        return response;
    },
    getNv2Separacao: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/separacao/${id}`, { headers });
        return response;
    },
    deleteSeparacao: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/separacao/${id}`, { headers });
        return response;
    },
    getFinalizacao: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/separacao/itens/${id}`, { headers });
        return response;
    },
    postFinalizacaoLiberacao: async (data) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/liberacao`, data, { headers });
        return response;
    },
    // RELATORIO ESTOQUE
    getRelatorioSolicitacao: async (obraId, projetoId, dataInicial, dataFinal) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/relatorio-estoque`, { obraId, projetoId, dataInicio: dataInicial, dataFinal }, { headers });
        return response;
    },
    shopingList: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/solicitacoes/relatorio-compra`, { headers });
        return response;
    }
});
