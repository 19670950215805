import { Box, Button, Modal } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useApi } from 'Service/axios';

//pdf viewer
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { printPlugin } from '@react-pdf-viewer/print';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { ShoppingReportPdf } from './ShoppingReportPdf';
import { pdf } from '@react-pdf/renderer';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24
};

export function ShoppingListReport() {
    const api = useApi();
    // PDF MODAL VIEWER PLUGINS
    const getFilePluginInstance = getFilePlugin();
    const { DownloadButton } = getFilePluginInstance;
    const printPluginInstance = printPlugin();
    const { PrintButton } = printPluginInstance;
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover, CurrentScale } = zoomPluginInstance;
    const [pdfBlob, setPdfBlob] = useState(null);

    // blod

    const [pdfBlobUrl, setPdfBlobUrl] = useState(null);

    //modal
    const [open, setOpen] = useState(false);
    const [list, setList] = useState([]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [itensList, setItensList] = useState([]);
    const [solic, setSolic] = useState([]);
    const [allSolicitacoes, setAllSolicitacoes] = useState([]);

    async function getShoppingList() {
        try {
            const { data } = await api.shopingList(); // Chamada à API

            // Verificar se data.solicitacoes existe e é um array
            const solicitacoes = data.solicitacoes;
            if (!Array.isArray(solicitacoes)) {
                throw new Error('A resposta da API não contém um array de solicitações.');
            }
            setAllSolicitacoes(solicitacoes);

            const agrupados = agruparItensPorItemId(solicitacoes); // Agrupar itens
            const filtered = agrupados.filter((item) => item.quantidade_disponivel < item.quantidade);
            setList(filtered);
        } catch (error) {
            console.error('Erro ao obter lista de compras:', error.message);
        }
    }

    function agruparItensPorItemId(solicitacoes) {
        const agrupados = {};

        solicitacoes.forEach((solicitacao) => {
            if (solicitacao.item_id && Array.isArray(solicitacao.item_id)) {
                solicitacao.item_id.forEach((item) => {
                    if (item.mov_sumarizado && Array.isArray(item.mov_sumarizado)) {
                        item.mov_sumarizado.forEach((mov) => {
                            // Verificar se a solicitação tem status "Aberta"
                            if (solicitacao.status === 'Aberta') {
                                // Gerar chave de agrupamento considerando apenas o item_id
                                const chave = `${mov.item_id}`;
                                setItensList((prev) => [...prev, item]);

                                // Se a chave não existe, inicializa
                                if (!agrupados[chave]) {
                                    agrupados[chave] = {
                                        item_id: mov.item_id,
                                        descricao: mov.item.descricao,
                                        unidade_medida: mov.item.unidade_medida?.descricao,
                                        quantidade: item.quantidade, // Quantidade do item
                                        quantidade_disponivel: mov.quantidade_disponivel // Quantidade disponível no almoxarifado,
                                    };
                                } else {
                                    // Se já existe, soma as quantidades
                                    agrupados[chave].quantidade += item.quantidade;
                                    agrupados[chave].quantidade_disponivel += mov.quantidade_disponivel;
                                }
                            }
                        });
                    }
                });
            }
        });

        // Retorna os itens agrupados como um array
        return Object.values(agrupados);
    }

    useEffect(() => {
        const uniqueItems = Array.from(
            new Set(itensList.map((item) => item.solicitacao_id)) // Extrai IDs únicos
        ).map((id) => {
            // Retorna o primeiro item correspondente ao ID
            return itensList.find((item) => item.solicitacao_id === id);
        });
        console.log('🚀 ~ agruparItensPorItemId ~ uniqueItems:', uniqueItems);

        const allDataAgrupados = allSolicitacoes.filter((item) => uniqueItems.some((itens) => item.id === itens.solicitacao_id));
        console.log('🚀 ~ agruparItensPorItemId ~ allDataAgrupados:', allDataAgrupados);

        setSolic(allDataAgrupados);
    }, [itensList, allSolicitacoes]);

    useEffect(() => {
        if (open) getShoppingList(); // Chama a função ao abrir o modal
    }, [open]);

    useEffect(() => {
        const generateBlob = async () => {
            const asPdf = pdf(<ShoppingReportPdf data={list} solicitacoes={solic} />);
            const blob = await asPdf.toBlob();
            const blobUrl = URL.createObjectURL(blob);
            setPdfBlobUrl(blobUrl);
        };

        generateBlob();
    }, [list, solic]);

    return (
        <div>
            <Button onClick={handleOpen} variant="contained">
                Relatório de compras
            </Button>

            <Modal open={open} onClose={() => handleClose(false)}>
                <Box sx={{ ...style, width: '80vw', height: '80vh' }}>
                    {pdfBlobUrl && (
                        <>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gap: '1rem',
                                    alignItems: 'center',
                                    backgroundColor: '#eeeeee',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.3)'
                                }}
                            >
                                <DownloadButton />
                                <PrintButton />
                                <ZoomOutButton />
                                <ZoomPopover />
                                <ZoomInButton />
                            </Box>
                            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
                                <Viewer
                                    defaultScale={1.5}
                                    fileUrl={pdfBlobUrl}
                                    plugins={[getFilePluginInstance, printPluginInstance, zoomPluginInstance]}
                                />
                            </Worker>
                        </>
                    )}
                </Box>
            </Modal>
        </div>
    );
}
