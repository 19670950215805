'use strict';
import { useParams, useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Tooltip, TextField, FormControl, MenuItem, Typography, Menu } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
import ActionButtonEdit from 'components/Estoque/Solicitacao/EDIT/ActionButtonEdit';
import ActionButtonsEditCreate from 'components/Estoque/Solicitacao/ActionButtonsCreate';
import { CircularProgress } from '@mui/material';
import CreateContructionModal from '../components/Obras';
import CadastroItensModal from '../components/CadastroItens';
import CadastroProjetosModal from '../components/CadastroProjetos/CadastroProjetosModal';
import { IconPlus } from '@tabler/icons';
const { v4: uuidv4 } = require('uuid');
// ============================= INITIALSTATE ==============================
const initialState = {
    emissao: new Date(),
    necessidade: '',
    obra: '',
    projeto: '',
    observacao: '',
    itens: []
};

const EditarSolicitacao = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    const navigate = useNavigate();
    const { id } = useParams();
    // ============================= STATES =============================
    const [campos, setCampos] = useState(initialState);
    const [openCreate, setOpenCreate] = useState(false);

    const [itens, setItens] = useState([]);
    const [listAllItens, setListAllItens] = useState([]);
    const [listAllObras, setListAllObras] = useState([]);
    const [listAllProjetos, setListAllProjetos] = useState([]);
    const [filtredProjetos, setFiltredProjetos] = useState([]);

    const columns = [
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 0.5 },
        { field: 'unidade', headerName: 'UN', flex: 0.3, sortable: false },
        { field: 'quantidade', headerName: 'QUANTIDADE', flex: 0.3 },
        {
            field: 'quantidade_estoque',
            headerName: 'SALDO',
            flex: 0.3,
            renderCell: (params) => (
                <Box display="flex" alignItems="center">
                    <Box>{params.row.quantidade_estoque}</Box>
                    <Box
                        component="span"
                        sx={{
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            backgroundColor: parseInt(params.row.quantidade_estoque) < parseInt(params.row.quantidade) ? 'red' : 'green',
                            marginLeft: 1
                        }}
                    />
                </Box>
            )
        },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                <ActionButtonEdit
                    setCampos={setCampos}
                    itens={itens}
                    setItens={setItens}
                    row={params.row}
                    atualizarCampoItem={atualizarCampoItem}
                    removerCampoItem={removerCampoItem}
                    listAllItens={listAllItens}
                />
            )
        }
    ];
    // ============================= FUNÇÕES =============================

    const handleClose = () => {
        setOpenCreate(false);
    };

    const handleFilterProjetos = (value) => {
        const newList = listAllProjetos.filter((el) => el.obra_id === value);
        setFiltredProjetos(newList);
    };

    const atualizarCampo = (campo, valor) => {
        setCampos((prev) => ({ ...prev, [campo]: valor }));
    };

    const adicionarCampoItem = () => {
        setCampos((prevCampos) => ({
            ...prevCampos,
            itens: [
                ...prevCampos.itens,
                {
                    action: 'create',
                    item_id: '',
                    descricao: '',
                    quantidade: 0,
                    unidade: '',
                    indiceCampoItem: ''
                }
            ]
        }));
    };

    const removerCampoItem = (uuid) => {
        setItens((prevItens) => {
            const novosItens = prevItens.filter((item) => item.uuid !== uuid);
            return novosItens;
        });

        setCampos((prevCampos) => {
            const novosCampos = { ...prevCampos };
            const indiceItem = novosCampos.itens.findIndex((item) => item.uuid === uuid);

            if (indiceItem !== -1) {
                const item = novosCampos.itens[indiceItem];
                item.action = 'delete';
            }

            return novosCampos;
        });

        // Fechar modal
        if (openCreate) {
            setOpenCreate(false);
        }
    };

    const atualizarCampoItem = async (uuid, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = { ...prevCampos };
            novosCampos.itens[campos?.itens.length - 1][campo] = valor;
            return novosCampos;
        });
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const handleCreate = (indiceCampo) => {
        adicionarCampoItem(indiceCampo);
    };

    const handleOpenAndCreateCampoItem = () => {
        setOpenCreate(true);
        adicionarCampoItem();
    };

    const getSolicitacaoById = async (solicitacaoId) => {
        const [itens, obras, projetos] = await Promise.all([api.getAllItens(), api.getAllObra(), api.getAllProjeto()]);
        setListAllItens(itens.data.itens);
        setListAllObras(obras.data.obras);
        setListAllProjetos(projetos.data.projetos);

        // --- get da solicitação --- //

        const response = await api.getSolicitacaoById(solicitacaoId);
        // ==== montando objeto de cada item ====
        const data = response.data.data;
        data.item_id.forEach((item) => {
            item.uuid = uuidv4();
            item.descricao = item.item.descricao;
            item.unidade = item.item.unidade_medida.descricao;
            item.action = 'update';
            delete item.item;
        });
        // filtro de projetos por obra
        const newList = projetos.data.projetos.filter((el) => el.obra_id === data.obra_id);
        setFiltredProjetos(newList);
        //==== montando body de campos ====
        const objeto = {
            id: data.id,
            emissao: data.data,
            necessidade: data.necessidade,
            obra: data.obra.id,
            projeto: data.projeto.id,
            observacao: data.observacao,
            itens: data.item_id
        };
        setCampos(objeto);
        // setItens(data.item_id);
        data.item_id.forEach((item) => adicionarItemNaTabela(item));
    };

    const getProject = async () => {
        try {
            const { data } = await api.getAllProjeto();
            setListAllProjetos(data.projetos);
        } catch (error) {
            console.log('🚀 ~ getProject ~ error:', error);
        }
    };

    const getItens = async () => {
        try {
            const { data } = await api.getAllItens();
            setListAllItens(data?.itens);
        } catch (error) {
            console.log('🚀 ~ getItens ~ error:', error);
        }
    };

    const adicionarItemNaTabela = async (novoItem) => {
        try {
            const { data } = await api.getMovSumarizadoByItemId(novoItem?.item_id);

            const quantidadeItemEstoque = data.find((element) => element?.item_id === novoItem?.item_id);
            // const quantidadeItemEstoque = data.find(
            //     (element) => element?.almoxarifado_id === novoItem?.almoxarifado_id && element?.solicitacao_id === novoItem?.solicitacao_id
            // );

            // Adiciona o campo 'quantidade_estoque' ao novoItem
            const novoItemComQuantidade = {
                ...novoItem,
                quantidade_estoque: quantidadeItemEstoque?.quantidade_disponivel
            };

            // Atualiza o estado preservando os itens anteriores
            setItens((prevItens) => [...prevItens, novoItemComQuantidade]);
        } catch (error) {
            toast.error(`Erro ao buscar dados do item: ${error.message}`);
        }
    };

    const getDate = () => {
        const date = new Date();
        let currentDay = String(date.getDate()).padStart(2, '0');
        let currentMonth = String(date.getMonth() + 1).padStart(2, '0');
        let currentYear = date.getFullYear();
        let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        setCampos((prev) => ({ ...prev, movimento: currentDate }));
    };

    useEffect(() => {
        getSolicitacaoById(id);
        getDate();
    }, [id]);

    //====== SUBMIT ======
    const handleSubmit = async (e, campos) => {
        e.preventDefault();

        try {
            // Filtra itens com ação 'delete' ou descrição vazia
            const itensFiltrados = campos.itens.filter((item) => item.action !== 'delete' && item.descricao !== '');

            // Atualiza a ação dos itens no estado 'itens'
            const novosItens = itens.map((item) => {
                const itemCampos = itensFiltrados.find((itemCampo) => itemCampo.uuid === item.uuid);

                if (itemCampos) {
                    item.action = itemCampos.action;
                }

                // Remove 'id' se a ação for 'create'
                if (item.action === 'create') {
                    delete item.id;
                }

                return item;
            });

            // Atualiza os itens deletados
            novosItens.push(...campos.itens.filter((item) => item.action === 'delete'));

            // Envia a requisição para atualizar a entrada de estoque
            await api.updateSolicitacao(campos.id, campos, novosItens);

            setCampos(undefined);
            setCampos([
                {
                    emissao: new Date(),
                    necessidade: '',
                    obra: '',
                    projeto: '',
                    observacao: '',
                    itens: []
                }
            ]);
            setItens([]);
            setOpenCreate(false);
            navigate('/main/solicitacao');
            toast.success('Solicitação Atualizada');
        } catch (error) {
            toast.error(`Erro: ${error?.response?.data?.message}`);
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <MainCard title="Editar Solicitação">
            <Grid container spacing={gridSpacing}>
                <Box sx={{ width: '80%', margin: '0 auto' }}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '1em',
                            marginTop: '1em',
                            flexWrap: 'wrap'
                        }}
                    >
                        <div>
                            <Button
                                sx={{ position: 'absolute', top: '130px', right: '50px' }}
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                variant="contained"
                            >
                                <IconPlus />
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleCloseMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button'
                                }}
                            >
                                <MenuItem>
                                    <CreateContructionModal setListAllObras={setListAllObras} />
                                </MenuItem>
                                <MenuItem>
                                    <CadastroItensModal getItens={getItens} />
                                </MenuItem>
                                <MenuItem>
                                    <CadastroProjetosModal allObra={listAllObras} getAllProjeto={getProject} />
                                </MenuItem>
                            </Menu>
                        </div>
                        <ActionButtonsEditCreate
                            open={openCreate}
                            handleClose={handleClose}
                            itens={itens}
                            setItens={setItens}
                            campos={campos}
                            setCampos={setCampos}
                            adicionarCampoItem={adicionarCampoItem}
                            atualizarCampoItem={atualizarCampoItem}
                            removerCampoItem={removerCampoItem}
                            listAllItens={listAllItens}
                            solicitacaoId={id}
                        />

                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '1em',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginBottom: '1em',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <FormControl fullWidth sx={{ maxWidth: '300px' }}>
                                    <TextField
                                        type="date"
                                        label="Emissão"
                                        value={campos.emissao}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            sx: { height: '50px' } // define a altura diretamente no campo de entrada
                                        }}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ maxWidth: '300px' }}>
                                    <TextField
                                        type="date"
                                        value={campos.necessidade}
                                        onChange={(e) => atualizarCampo('necessidade', e.target.value)}
                                        label="Necessidade"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            sx: { height: '50px' } // define a altura diretamente no campo de entrada
                                        }}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ maxWidth: '300px' }}>
                                    <TextField
                                        select
                                        value={campos.obra}
                                        label="Obra"
                                        sx={{ height: '50px' }}
                                        onChange={(e) => {
                                            atualizarCampo('obra', e.target.value), handleFilterProjetos(e.target.value);
                                        }}
                                    >
                                        {listAllObras.map((obra) => (
                                            <MenuItem key={obra.id} value={obra.id}>
                                                {obra.descricao}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                                <FormControl fullWidth sx={{ maxWidth: '300px' }}>
                                    <TextField
                                        select
                                        sx={{ height: '50px' }}
                                        value={campos.projeto}
                                        label="Projeto"
                                        onChange={(e) => atualizarCampo('projeto', e.target.value)}
                                    >
                                        {filtredProjetos.map((projeto) => (
                                            <MenuItem key={projeto.id} value={projeto.id}>
                                                {projeto.descricao}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl sx={{ width: '100%', maxWidth: '500px' }}>
                                    <TextField
                                        multiline
                                        minRows={3}
                                        placeholder="Observação"
                                        label="Observação"
                                        InputLabelProps={{ shrink: true }}
                                        value={campos.observacao}
                                        onChange={(e) => atualizarCampo('observacao', e.target.value)}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', marginTop: '2em', borderTop: '2px dashed #eee' }}>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '1em' }}>
                                <Box>
                                    <Typography variant="h4">Relação de itens</Typography>
                                </Box>
                                <Box>
                                    <Tooltip title="Adicionar item">
                                        <Button variant="contained" color="success" onClick={(e) => handleOpenAndCreateCampoItem()}>
                                            <AddIcon />
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '2em',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '300px',
                                    width: '100%',
                                    overflowX: 'auto'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        overflowX: 'auto'
                                    }}
                                >
                                    <DataGrid
                                        rows={itens}
                                        columns={columns}
                                        hideFooter={true}
                                        sx={{ minWidth: '1000px' }}
                                        onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                                        getRowId={(row) => row.uuid}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        width: '40%',
                                        marginLeft: 'auto',
                                        marginTop: '1em',
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Tooltip title="Salvar entrada">
                                        <Button variant="contained" color="success" onClick={(e) => handleSubmit(e, campos)}>
                                            <SaveIcon />
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default EditarSolicitacao;
