import { useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Tooltip, TextField, FormControl, MenuItem, Typography, Autocomplete, Menu } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import ActionButtons from 'components/Estoque/Solicitacao/ActionButtons';
import ActionButtonsCreate from 'components/Estoque/Solicitacao/ActionButtonsCreate';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
import CreateContructionModal from '../components/Obras';
import CadastroItensModal from '../components/CadastroItens';
import CadastroProjetosModal from '../components/CadastroProjetos/CadastroProjetosModal';
import { IconPlus } from '@tabler/icons';
const { v4: uuidv4 } = require('uuid');
// ============================= INITIALSTATE =============================
const initialState = {
    emissao: new Date(),
    necessidade: '',
    obra: '',
    projeto: '',
    observacao: '',
    itens: []
};

const NovaSolicitacao = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    const navigate = useNavigate();
    // ============================= STATES =============================
    const [campos, setCampos] = useState(initialState);
    const [openCreate, setOpenCreate] = useState(false);
    const [listAllItens, setListAllItens] = useState([]);
    const [listAllObras, setListAllObras] = useState([]);
    const [listAllProjetos, setListAllProjetos] = useState([]);
    const [filtredProjetos, setFiltredProjetos] = useState([]);
    const [itens, setItens] = useState([]);

    const columns = [
        { field: 'cod', headerName: 'COD', flex: 0.5, valueGetter: (params) => params.row.item_id },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 1 },
        { field: 'unidade', headerName: 'UN', flex: 0.5 },
        { field: 'quantidade', headerName: 'QUANTIDADE', flex: 0.5 },
        {
            field: 'quantidade_estoque',
            headerName: 'SALDO',
            flex: 0.5,
            renderCell: (params) => (
                <Box display="flex" alignItems="center">
                    <Box>{params.row.quantidade_estoque}</Box>
                    <Box
                        component="span"
                        sx={{
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            backgroundColor: parseInt(params.row.quantidade_estoque) < parseInt(params.row.quantidade) ? 'red' : 'green',
                            marginLeft: 1
                        }}
                    />
                </Box>
            )
        },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                <ActionButtons
                    setItens={setItens}
                    row={params.row}
                    removerCampoItem={removerCampoItem}
                    atualizarCampoItem={atualizarCampoItem}
                    listAllItens={listAllItens}
                />
            )
        }
    ];
    // ============================= FUNÇÕES =============================

    const handleClose = () => {
        setOpenCreate(false);
    };

    const handleFilterProjetos = (value) => {
        const newList = listAllProjetos.filter((el) => el.obra_id === value);
        setFiltredProjetos(newList);
    };

    const atualizarCampo = (campo, valor) => {
        setCampos((prev) => ({ ...prev, [campo]: valor }));
    };

    const adicionarCampoItem = () => {
        setCampos((prevCampos) => ({
            ...prevCampos,
            itens: [
                ...prevCampos.itens,
                {
                    item_id: '',
                    descricao: '',
                    quantidade: 0,
                    unidade: '',
                    indiceCampoItem: ''
                }
            ]
        }));
    };

    const removerCampoItem = (uuid) => {
        setItens((prevItens) => {
            const novosItens = [...prevItens];
            const filtredItens = novosItens.filter((item) => item.uuid != uuid);
            return filtredItens;
        });
        setCampos((prevCampos) => {
            const novosCampos = { ...prevCampos };
            novosCampos.itens.filter((item) => item.uuid != uuid);
            return novosCampos;
        });

        if (openCreate) {
            setOpenCreate(false);
        }
    };

    const atualizarCampoItem = async (uuid, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = { ...prevCampos };
            novosCampos.itens[campos?.itens.length - 1][campo] = valor;
            return novosCampos;
        });
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const handleOpenAndCreateCampoItem = () => {
        setOpenCreate(true);
        adicionarCampoItem();
    };

    const handleCreate = (indiceCampo) => {
        adicionarCampoItem(indiceCampo);
    };

    const getDependency = async () => {
        try {
            const [itens, obras, projetos] = await Promise.all([api.getAllItens(), api.getAllObra(), api.getAllProjeto()]);
            setListAllItens(itens?.data?.itens);
            setListAllObras(obras?.data?.obras);
            setListAllProjetos(projetos?.data?.projetos);
            console.log('funfou');
        } catch (error) {
            console.log('🚀 ~ getDependency ~ error:', error);
        }
    };

    const getProject = async () => {
        try {
            const { data } = await api.getAllProjeto();
            setListAllProjetos(data.projetos);
        } catch (error) {
            console.log('🚀 ~ getProject ~ error:', error);
        }
    };

    const getItens = async () => {
        try {
            const { data } = await api.getAllItens();
            setListAllItens(data?.itens);
        } catch (error) {
            console.log('🚀 ~ getItens ~ error:', error);
        }
    };

    const getDate = () => {
        const date = new Date();
        let currentDay = String(date.getDate()).padStart(2, '0');
        let currentMonth = String(date.getMonth() + 1).padStart(2, '0');
        let currentYear = date.getFullYear();
        let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        setCampos((prev) => ({ ...prev, movimento: currentDate }));
    };

    useEffect(() => {
        getDependency();
        getDate();
    }, []);

    //====== SUBMIT ======
    const handleSubmit = async (e, campo) => {
        e.preventDefault();
        if (campo.necessidade == '' || campo.obra == '' || campo.projeto == '' || campo.itens.length == 0) {
            return toast.warning('Data de necessidade, obra e projeto é obrigatório');
        }
        itens.forEach((item) => {
            delete item.id;
        });
        const data = { entrada: campo, itens: itens };

        try {
            await api.createSolicitacao(data.entrada, data.itens);
            setCampos(undefined);
            setCampos({
                emissao: new Date(),
                necessidade: '',
                obra: '',
                projeto: '',
                observacao: '',
                itens: []
            });
            setItens([]);
            setOpenCreate(false);
            toast.success('Solicitação cadastrada');
        } catch (error) {
            toast.error(`Erro ao criar solicitação: ${error.message}`);
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <MainCard title="Nova Solicitação">
            <Grid container spacing={gridSpacing}>
                <div>
                    <Button
                        sx={{ position: 'absolute', top: '130px', right: '50px' }}
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        variant="contained"
                    >
                        <IconPlus />
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseMenu}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button'
                        }}
                    >
                        <MenuItem>
                            <CreateContructionModal setListAllObras={setListAllObras} />
                        </MenuItem>
                        <MenuItem>
                            <CadastroItensModal getItens={getItens} />
                        </MenuItem>
                        <MenuItem>
                            <CadastroProjetosModal allObra={listAllObras} getAllProjeto={getProject} />
                        </MenuItem>
                    </Menu>
                </div>

                <ActionButtonsCreate
                    open={openCreate}
                    itens={itens}
                    setItens={setItens}
                    atualizarCampoItem={atualizarCampoItem}
                    removerCampoItem={removerCampoItem}
                    listAllItens={listAllItens}
                    handleCreate={handleCreate}
                    campos={campos}
                    adicionarCampoItem={adicionarCampoItem}
                    handleClose={handleClose}
                />
                <Box sx={{ width: '80%', margin: '0 auto' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginLeft: '1em', marginTop: '1em' }}>
                        <Box>
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '1em',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginBottom: '1em',
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <FormControl fullWidth sx={{ maxWidth: '300px' }}>
                                        <TextField
                                            type="date"
                                            label="Emissão"
                                            InputProps={{
                                                sx: { height: '50px' } // define a altura diretamente no campo de entrada
                                            }}
                                            value={campos.emissao}
                                            disabled
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth sx={{ maxWidth: '300px' }}>
                                        <TextField
                                            type="date"
                                            value={campos.necessidade}
                                            onChange={(e) => atualizarCampo('necessidade', e.target.value)}
                                            label="Necessidade"
                                            InputProps={{
                                                sx: { height: '50px' } // define a altura diretamente no campo de entrada
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ maxWidth: '300px' }}>
                                        <Autocomplete
                                            options={listAllObras}
                                            sx={{ height: '50px' }}
                                            getOptionLabel={(obra) => `${obra.id} - ${obra.descricao}`}
                                            value={listAllObras.find((obra) => obra.id === campos.obra) || null}
                                            onChange={(e, newValue) => {
                                                atualizarCampo('obra', newValue ? newValue.id : null);
                                                handleFilterProjetos(newValue ? newValue.id : null);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Obra" />}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ maxWidth: '300px' }}>
                                        <Autocomplete
                                            sx={{ height: '50px' }}
                                            options={filtredProjetos}
                                            getOptionLabel={(projeto) => `${projeto.id} - ${projeto.descricao}`}
                                            value={filtredProjetos.find((projeto) => projeto.id === campos.projeto) || null}
                                            onChange={(e, newValue) => atualizarCampo('projeto', newValue ? newValue.id : null)}
                                            renderInput={(params) => <TextField {...params} label="Projeto" />}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl sx={{ width: '100%', maxWidth: '500px' }}>
                                        <TextField
                                            multiline
                                            minRows={3}
                                            placeholder="Observação"
                                            value={campos.observacao}
                                            onChange={(e) => atualizarCampo('observacao', e.target.value)}
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box sx={{ width: '100%', marginTop: '2em', borderTop: '2px dashed #eee' }}>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '1em' }}>
                                    <Box>
                                        <Typography variant="h4">Relação de itens</Typography>
                                    </Box>
                                    <Box>
                                        <Tooltip title="Adicionar item">
                                            <Button variant="contained" color="success" onClick={(e) => handleOpenAndCreateCampoItem()}>
                                                <AddIcon />
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </Box>

                                <Box sx={{ marginTop: '2em', display: 'flex', flexDirection: 'column', height: '300px' }}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            overflowX: 'auto'
                                        }}
                                    >
                                        <DataGrid
                                            rows={itens}
                                            columns={columns}
                                            sx={{ minWidth: '1000px' }}
                                            hideFooter={true}
                                            onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                                            getRowId={(row) => row.uuid}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '40%',
                                            marginLeft: 'auto',
                                            marginTop: '1em',
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}
                                    >
                                        <Tooltip title="Salvar entrada">
                                            <Button variant="contained" color="success" onClick={(e) => handleSubmit(e, campos)}>
                                                <SaveIcon />
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default NovaSolicitacao;
