import React from 'react';
import { useApi } from 'Service/axios';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const ActionButtonsTabela = ({ row, getSolicitacoes }) => {
    /////////////////////////////// INSTANCIAS /////////////////////////
    const api = useApi();
    /////////////////////////////// STATES /////////////////////////
    const [openDialog, setOpenDialog] = useState(false);
    /////////////////////////////// FUNÇÕES /////////////////////////
    const handleDeleteSolicitacao = async (solicitacaoId) => {
        try {
            await api.deleteSolicitacao(solicitacaoId);
            getSolicitacoes();
            setOpenDialog(false);
            toast.success('Solicitação deletada com sucesso');
        } catch (error) {
            toast.error(`Erro ao deletar Solicitação: ${error.response.data.messagem}`);
        }
    };
    //teste
    return (
        <Box>
            <Dialog open={openDialog}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>Tem certeza que deseja excluir essa solicitação?</DialogContent>
                <DialogActions>
                    <Button color="error" onClick={() => setOpenDialog(false)}>
                        não
                    </Button>
                    <Button variant="contained" onClick={() => handleDeleteSolicitacao(row.id)}>
                        sim
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <Tooltip title="Editar Solicitação">
                    <Link to={`/main/solicitacao/${row.id}`}>
                        <Button>
                            <EditIcon />
                        </Button>
                    </Link>
                </Tooltip>
                <Tooltip title="Remover Solicitação">
                    <Button onClick={() => setOpenDialog(true)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
            </div>
        </Box>
    );
};

export default ActionButtonsTabela;
