import { TableCell, styled } from '@mui/material';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

// components

export const MainBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    marginTop: '1.5em',
    padding: '.5em'
});

export const HeaderBox = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em',
    marginLeft: '.7em',
    marginTop: '1em'
});

export const TableBox = styled(Box)({
    width: '100%',
    overflowX: 'auto' // Adiciona rolagem horizontal no contêiner pai
});

export const TableDatagrid = styled(DataGrid)({
    minWidth: '1400px', // Define uma largura mínima fixa para a tabela
    height: '100%', // Faz a tabela ocupar toda a altura do contêiner pai,
    maxHeight: `calc(100vh - 390px)`
});

export const OrderButton = styled(TableCell)({
    cursor: 'pointer',
    transition: 'all .15s ease',

    b: {
        marginRight: '1em'
    },
    '&:hover': {
        backgroundColor: 'rgba(39, 157, 245, 0.52)'
    }
});

export const OrderNameButton = styled(TableCell)({
    cursor: 'pointer',
    transition: 'all .15s ease',

    b: {
        marginRight: '1em'
    },
    '&:hover': {
        backgroundColor: 'rgba(39, 157, 245, 0.52)'
    }
});
