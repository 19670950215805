import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { removeMask } from 'utils/removeMask';
import { useApi } from 'Service/axios';
import { FormBox } from './style';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { maxWidth } from '@mui/system';

const CadastroObrasModal = ({ getConstruction, ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const api = useApi();
    const [submitError, setSubmitError] = useState(null);

    function isAnyFieldEmpty(values) {
        const fieldsToCheck = ['descricao'];

        for (const field of fieldsToCheck) {
            if (values[field].trim() === '') {
                return true;
            }
        }

        return false;
    }

    const handleCepChange = async (event, changeValue) => {
        const cep = removeMask(event.target.value);

        if (cep.length === 8) {
            try {
                const cepData = await api.getCepLocation(cep);

                console.log(cepData, 'cepData');

                if (cepData) {
                    const { bairro, localidade, tipo_logradouro, logradouro, uf } = cepData?.data;
                    changeValue('bairro', bairro);
                    changeValue('cidade', localidade);
                    changeValue('rua', logradouro);
                    changeValue('estado', uf);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleRegister = async (values) => {
        values.cep = removeMask(values.cep);
        values.telefone = removeMask(values.telefone);

        try {
            await api.createObra(values);
            toast.success('Cadastrado com sucesso!');

            await getConstruction();
        } catch (error) {
            console.log('🚀 ~ handleRegister ~ error:', error);
        }
    };

    const clearValues = (values) => {
        values.descricao = '';
        values.cep = '';
        values.rua = '';
        values.numero = '';
        values.bairro = '';
        values.estado = '';
        values.cidade = '';
        values.tipo_logradouro = '';
        values.complemento = '';
        values.contato = '';
        values.telefone = '';
    };

    return (
        <Formik
            initialValues={{
                descricao: '',
                cep: '',
                rua: '',
                numero: '',
                bairro: '',
                estado: '',
                cidade: '',
                tipo_logradouro: '',
                complemento: '',
                contato: '',
                telefone: '',
                submit: null
            }}
            validationSchema={Yup.object().shape({
                descricao: Yup.string().required('Descrição é obrigatório'),
                cep: Yup.string().required('CEP é obrigatório'),
                rua: Yup.string().required('Rua é obrigatório'),
                bairro: Yup.string().required('Bairro é obrigatório'),
                cidade: Yup.string().required('Cidade é obrigatório'),
                estado: Yup.string().max(2).required('Estado é obrigatório'),
                tipo_logradouro: Yup.string().required('tipo_logradouro é obrigatório')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    if (scriptedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                    }
                    handleRegister(values);
                    clearValues(values);
                } catch (err) {
                    if (scriptedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.msg });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                <form
                    noValidate
                    onSubmit={handleSubmit}
                    {...others}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '1120px',
                        margin: '0 auto',
                        '@media (max-width: 1550px)': {
                            maxWidth: '550px'
                        }
                    }}
                >
                    <FormBox>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.descricao && errors.descricao)}
                            sx={{ ...theme.typography.customInput, maxWidth: '550px' }}
                        >
                            <InputLabel htmlFor="outlined-adornment-descricao-register">Descrição</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-descricao-register"
                                type="text"
                                value={values.descricao}
                                name="descricao"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.descricao && errors.descricao && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.descricao}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.cep && errors.cep)}
                            sx={{ ...theme.typography.customInput, maxWidth: '550px' }}
                        >
                            <InputLabel htmlFor="outlined-adornment-cep-register">CEP</InputLabel>
                            <InputMask
                                mask="99999-999"
                                value={values.cep}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleCepChange(e, setFieldValue);
                                    handleChange(e);
                                }}
                            >
                                {(inputProps) => (
                                    <OutlinedInput id="outlined-adornment-cep-register" type="text" name="cep" inputProps={inputProps} />
                                )}
                            </InputMask>
                            {touched.cep && errors.cep && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.cep}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </FormBox>

                    <FormBox>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.rua && errors.rua)}
                            sx={{ ...theme.typography.customInput, maxWidth: '550px' }}
                        >
                            <InputLabel htmlFor="outlined-adornment-rua-register">Logradouro</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-rua-register"
                                type="text"
                                value={values.rua}
                                name="rua"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.rua && errors.rua && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.rua}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.numero && errors.numero)}
                            sx={{ ...theme.typography.customInput, maxWidth: '550px' }}
                        >
                            <InputLabel htmlFor="outlined-adornment-numero-register">Número</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-numero-register"
                                type="number"
                                value={values.numero}
                                name="numero"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.numero && errors.numero && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.numero}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </FormBox>

                    <FormBox>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.bairro && errors.bairro)}
                            sx={{
                                ...theme.typography.customInput,
                                width: { sm: '100%', md: '550px', lg: '1200px' },
                                '@media (max-width:1550px)': {
                                    width: '550px' // Exemplo de largura específica entre md e lg
                                }
                            }}
                        >
                            <InputLabel htmlFor="outlined-adornment-bairro-register">bairro</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-bairro-register"
                                type="text"
                                value={values.bairro}
                                name="bairro"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.bairro && errors.bairro && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.bairro}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </FormBox>

                    <FormBox>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.cidade && errors.cidade)}
                            sx={{ ...theme.typography.customInput, maxWidth: '550px' }}
                        >
                            <InputLabel htmlFor="outlined-adornment-cidade-register">Cidade</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-cidade-register"
                                type="text"
                                value={values.cidade}
                                name="cidade"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.cidade && errors.cidade && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.cidade}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.estado && errors.estado)}
                            sx={{ ...theme.typography.customInput, maxWidth: '550px' }}
                        >
                            <InputLabel htmlFor="outlined-adornment-estado-register">Estado</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-estado-register"
                                type="text"
                                value={values.estado}
                                name="estado"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.estado && errors.estado && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.estado}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </FormBox>

                    <FormBox>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.tipo_logradouro && errors.tipo_logradouro)}
                            sx={{ ...theme.typography.customInput, maxWidth: '550px' }}
                        >
                            <InputLabel htmlFor="outlined-adornment-tipo_logradouro-register">Tipo logradouro</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-tipo_logradouro-register"
                                type="text"
                                value={values.tipo_logradouro}
                                name="tipo_logradouro"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.tipo_logradouro && errors.tipo_logradouro && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.tipo_logradouro}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.complemento && errors.complemento)}
                            sx={{ ...theme.typography.customInput, maxWidth: '550px' }}
                        >
                            <InputLabel htmlFor="outlined-adornment-complemento-register">Complemento</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-complemento-register"
                                type="text"
                                value={values.complemento}
                                name="complemento"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.complemento && errors.complemento && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.complemento}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </FormBox>

                    <FormBox>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.contato && errors.contato)}
                            sx={{ ...theme.typography.customInput, maxWidth: '550px' }}
                        >
                            <InputLabel htmlFor="outlined-adornment-contato-register">contato</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-contato-register"
                                type="text"
                                value={values.contato}
                                name="contato"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.contato && errors.contato && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.contato}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.telefone && errors.telefone)}
                            sx={{ ...theme.typography.customInput, maxWidth: '550px' }}
                        >
                            <InputLabel htmlFor="outlined-adornment-telefone-register">telefone</InputLabel>
                            <InputMask mask="(99) 99999-9999" value={values.telefone} onBlur={handleBlur} onChange={handleChange}>
                                {(inputProps) => (
                                    <OutlinedInput
                                        id="outlined-adornment-telefone-register"
                                        type="text"
                                        name="telefone"
                                        inputProps={inputProps}
                                    />
                                )}
                            </InputMask>
                            {touched.telefone && errors.telefone && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.telefone}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </FormBox>

                    {!submitError && errors.submit ? (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                    ) : (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>{submitError}</FormHelperText>
                        </Box>
                    )}

                    {/* BOTÕES */}
                    <Box sx={{ mt: 2 }}>
                        <AnimateButton>
                            <Button
                                sx={{ marginBottom: '3em' }}
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {isSubmitting ? 'Dados da obra estão faltando' : 'Cadastrar obra'}
                            </Button>
                        </AnimateButton>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default CadastroObrasModal;
