import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import LogoBLuVale from '../../../../assets/images/logobv.jpg';
import LogoBw from '../../../../assets/images/BW TECNOLOGIA.png';
import { symbol } from 'prop-types';
import formater from 'utils/DateDDMMYYY';

const styles = {
    page: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingHorizontal: 15,
        backgroundColor: '#fff',
        fontSize: 10,
        fontWeight: 'normal',
        rap: 'wrap',
        position: 'relative'
    },

    box: {
        border: '1px solid #cac3c3',
        padding: 5,

        borderRadius: 5
    },
    row: { flexDirection: 'row', borderBottom: '0.5 solid #cac3c3' },
    cell: { flexGrow: 1, padding: 5 },
    fixed: {
        bottom: 0,
        left: 0,
        width: '100%',
        padding: 10,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
};

function DinamicTable({ dados }) {
    const styles = {
        table: { width: '100%', borderCollapse: 'collapse' },
        cell: { borderBottom: '0.5px solid #cac3c3', padding: '5px', wordWrap: 'break-word', display: 'flex', justifyContent: 'center' }
    };

    return (
        <View style={styles.box}>
            <View style={styles.table}>
                <View
                    style={{ flexDirection: 'row', backgroundColor: '#eee', borderRadiusBottomLeft: '5px', borderRadiusBottomRight: '5px' }}
                >
                    <View style={{ ...styles.cell, width: '10%' }}>
                        <Text style={{ fontWeight: 'bold' }}>Cod.item</Text>
                    </View>
                    <View style={{ ...styles.cell, width: '35%' }}>
                        <Text style={{ fontWeight: 'bold' }}>Descrição</Text>
                    </View>
                    <View style={{ ...styles.cell, width: '10%' }}>
                        <Text style={{ fontWeight: 'bold' }}>Unidade</Text>
                    </View>
                    <View style={{ ...styles.cell, width: '15%', textAlign: 'center' }}>
                        <Text style={{ fontWeight: 'bold' }}>Qtde Solicitada</Text>
                    </View>

                    <View style={{ ...styles.cell, width: '15%', textAlign: 'center' }}>
                        <Text style={{ fontWeight: 'bold' }}>Estoque atual</Text>
                    </View>
                    <View style={{ ...styles.cell, width: '15%', textAlign: 'center' }}>
                        <Text style={{ fontWeight: 'bold' }}>Qtde de compra</Text>
                    </View>
                </View>

                {dados?.map((item, index) => (
                    <View key={index} style={{ flexDirection: 'row' }}>
                        <View style={{ ...styles.cell, width: '10%' }}>
                            <Text>{item.item_id}</Text>
                        </View>
                        <View style={{ ...styles.cell, width: '35%' }}>
                            <Text>{item.descricao}</Text>
                        </View>
                        <View style={{ ...styles.cell, width: '10%' }}>
                            <Text>{item.unidade_medida}</Text>
                        </View>
                        <View style={{ ...styles.cell, width: '15%', textAlign: 'center' }}>
                            <Text>{item.quantidade}</Text>
                        </View>
                        <View style={{ ...styles.cell, width: '15%', textAlign: 'center' }}>
                            <Text>{item.quantidade_disponivel}</Text>
                        </View>
                        <View style={{ ...styles.cell, width: '15%', textAlign: 'center' }}>
                            <Text>{item.quantidade - item.quantidade_disponivel}</Text>
                        </View>
                    </View>
                ))}
            </View>
        </View>
    );
}
function DinamicTableSolicitações({ dados }) {
    const styles = {
        table: { width: '100%', borderCollapse: 'collapse' },
        cell: { borderBottom: '0.5px solid #cac3c3', padding: '5px', wordWrap: 'break-word', display: 'flex', justifyContent: 'center' }
    };

    return (
        <View style={styles.box}>
            <View style={styles.table}>
                <View
                    style={{ flexDirection: 'row', backgroundColor: '#eee', borderRadiusBottomLeft: '5px', borderRadiusBottomRight: '5px' }}
                >
                    <View style={{ ...styles.cell, width: '10%' }}>
                        <Text style={{ fontWeight: 'bold' }}>ID</Text>
                    </View>
                    <View style={{ ...styles.cell, width: '45%' }}>
                        <Text style={{ fontWeight: 'bold' }}>Obra</Text>
                    </View>
                    <View style={{ ...styles.cell, width: '15%' }}>
                        <Text style={{ fontWeight: 'bold' }}>Projeto</Text>
                    </View>

                    <View style={{ ...styles.cell, width: '20%', textAlign: 'center' }}>
                        <Text style={{ fontWeight: 'bold' }}>Data da necessidade</Text>
                    </View>
                    <View style={{ ...styles.cell, width: '10%', textAlign: 'center' }}>
                        <Text style={{ fontWeight: 'bold' }}>Solicitante</Text>
                    </View>
                </View>

                {dados?.map((item, index) => (
                    <View key={index} style={{ flexDirection: 'row' }}>
                        <View style={{ ...styles.cell, width: '10%' }}>
                            <Text>{item.id}</Text>
                        </View>

                        <View style={{ ...styles.cell, width: '45%' }}>
                            <Text>{item.obra.descricao}</Text>
                        </View>
                        <View style={{ ...styles.cell, width: '15%' }}>
                            <Text>{item.projeto.descricao}</Text>
                        </View>
                        <View style={{ ...styles.cell, width: '20%', textAlign: 'center' }}>
                            <Text>{formater(item.necessidade)}</Text>
                        </View>
                        <View style={{ ...styles.cell, width: '10%', textAlign: 'center' }}>
                            <Text>{item.solicitante.nome_completo}</Text>
                        </View>
                    </View>
                ))}
            </View>
        </View>
    );
}

export function ShoppingReportPdf({ data, solicitacoes }) {
    console.log('🚀 ~ ShoppingReportPdf ~ data:', data);
    const name = localStorage.getItem('nome');
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const date = `${dd}/${mm}/${yyyy}`;
    return (
        <Document>
            <Page style={styles.page} orientation="landscape">
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <Image src={LogoBLuVale} style={{ width: '130px' }} />
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px', textAlign: 'center' }}>Bluvale - Engenharia e climatização</Text>
                        <Text style={{ fontSize: '14px', textAlign: 'center' }}>Relatório de compra</Text>
                    </View>
                    <View style={styles.header}>
                        <View style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                            <Text>Solicitante:</Text>
                            <Text style={styles.subtitle}>{name}</Text>
                        </View>
                        <Text style={styles.subtitle}>{date}</Text>
                    </View>
                </View>

                {/* tabela */}
                <View style={{ marginBottom: '10px', marginTop: '10px' }}>
                    <View style={{ backgroundColor: '#cecece', padding: '5px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                        <Text style={{ fontSize: '15px' }}>Necessidade de compra</Text>
                    </View>
                    <View style={styles.row}></View>
                    <View>
                        <DinamicTable dados={data} />
                    </View>
                </View>

                {/* lista de solicitações */}
                <View style={{ marginTop: '40px' }}>
                    <View style={{ backgroundColor: '#cecece', padding: '5px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                        <Text style={{ fontSize: '15px' }}>Obras atendidas</Text>
                    </View>
                    <View style={styles.row}></View>
                </View>

                <View>
                    <DinamicTableSolicitações dados={solicitacoes} />
                </View>

                {/* footer */}
                <View fixed style={styles.fixed}>
                    <Text>
                        Caminho do sistema: https://bluvale.bwtecnologia.com.br {'>>'} Modulo Estoque {'>>'} Solicitações {'>>'} Relatório
                        de compras
                    </Text>
                </View>
            </Page>
        </Document>
    );
}
