import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const FormBox = styled(Box)({
    display: 'flex',
    gap: '1em',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center'
});
