import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    Box,
    TableContainer,
    IconButton,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { FormBox, HeaderBox, MainBox, TableBox } from './style';
import CadastroProjeto from 'components/Projetos/CriarProjeto';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

function Projetos() {
    // == INSTÂNCIAS ==
    const api = useApi();
    // === STATES ===
    const [formOpen, setFormOpen] = useState(false);
    const [allObra, setAllObra] = useState([]);
    const [allProjetos, setAllProjetos] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const [projetoId, setProjetoId] = useState(null);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 1 },
        { field: 'obra', headerName: 'OBRA', flex: 1, valueGetter: (params) => params.row.obra.descricao },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Deletar Projeto">
                        <IconButton onClick={(e) => handleOpenDelete(e, params.row.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar Projeto">
                        <Link to={`/main/projetos/edit/${params.row.id}`}>
                            <IconButton>
                                <EditIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </Box>
            )
        }
    ];
    // === FUNÇÕES ===

    const handleOpenDelete = (e, id) => {
        e.preventDefault();
        setOpenDelete(true);
        setProjetoId(id);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleCancelDeleteDialog = (e) => {
        setOpenDelete(false);
    };

    const handleDeleteItem = async (e, projetoId) => {
        e.preventDefault();
        try {
            await api.deleteProjeto(projetoId);
            toast.success('Deletado com sucesso!');
            getAllProjeto();
        } catch (error) {
            if (error.response.menssgem) {
                toast.error(`ERRO: ${error.response.menssgem}`);
            }
            toast.error(error.message);
            setOpenDelete(false);
        }
    };

    const getAllObra = async () => {
        const response = await api.getAllObra();
        setAllObra(response.data.obras);
    };

    const getAllProjeto = async () => {
        try {
            const response = await api.getAllProjeto();
            setAllProjetos(response.data.projetos);
        } catch (error) {
            toast.error('Ocorreu um erro ao buscar os dados');
        }
    };

    useEffect(() => {
        getAllObra();
        getAllProjeto();
    }, []);

    return (
        <MainCard title="Projetos">
            <Grid container spacing={gridSpacing}>
                <Dialog
                    open={openDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="alert-dialog-title-delete"
                    aria-describedby="alert-dialog-description-delete"
                >
                    <DialogTitle id="alert-dialog-title-delete">Deletar Registro</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description-delete">
                            Tem certeza que deseja excluir o registo ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(e) => handleDeleteItem(e, projetoId)}>Sim</Button>
                        <Button onClick={handleCancelDeleteDialog}>Cancelar</Button>
                    </DialogActions>
                </Dialog>
                <MainBox sx={{ display: 'flex', justifyContent: 'center', padding: '1em', marginLeft: '.7em' }}>
                    {formOpen && (
                        <FormBox>
                            <Button variant="contained" onClick={() => setFormOpen(!formOpen)}>
                                Ver Tabela
                            </Button>
                            <Box sx={{ width: '80%', margin: '2em' }}>
                                <CadastroProjeto getAllProjeto={getAllProjeto} allObra={allObra} setFormOpen={setFormOpen} />
                            </Box>
                        </FormBox>
                    )}

                    {!formOpen && (
                        <TableBox sx={{ width: '100%' }}>
                            <HeaderBox>
                                <Button variant="contained" onClick={() => setFormOpen(!formOpen)}>
                                    Cadastrar Projeto
                                </Button>
                            </HeaderBox>
                            <Box sx={{ width: '100%', height: '450px' }}>
                                <DataGrid
                                    rows={allProjetos}
                                    columns={columns}
                                    onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                                />
                            </Box>
                        </TableBox>
                    )}
                </MainBox>
            </Grid>
        </MainCard>
    );
}

export default Projetos;
