import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Typography, Tab } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useApi } from 'Service/axios';
import { TableBox, TableDatagrid } from './style';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtonsTabela from 'components/Estoque/Solicitacao/ActionButtonTabela';
import formater from 'utils/DateDDMMYYY';
import { ShoppingListReport } from './components/ShoppingListReport';
import { TabContext, TabList, TabPanel } from '@mui/lab';

const SolicitacaoEstoque = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    const navigate = useNavigate();
    // ============================= STATES =============================
    const [allSolicitacao, setAllSolicitacao] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 450);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 450);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize); // Remove o listener no cleanup
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', sortable: false, flex: 0.2 },
        {
            field: 'data',
            headerName: 'DATA',
            flex: 0.4,
            valueGetter: (params) => formater(params?.row?.data)
        },
        {
            field: 'necessidade',
            headerName: 'NECESSIDADE',
            flex: 0.4,
            valueGetter: (params) => formater(params?.row?.necessidade),
            sortable: false
        },
        {
            field: 'obra',
            headerName: 'OBRA',
            flex: 0.5,
            valueGetter: (params) => params?.row?.obra?.descricao
        },
        {
            field: 'projeto',
            headerName: 'PROJETO',
            flex: 1,
            valueGetter: (params) => params?.row?.projeto?.descricao,
            sortable: false
        },
        { field: 'status', headerName: 'STATUS LIB', flex: 0.4, sortable: false },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => <ActionButtonsTabela row={params?.row} getSolicitacoes={getSolicitacoes} />
        }
    ];
    const columnsMobi = [
        {
            field: 'obra',
            headerName: 'OBRA',
            flex: 1,
            valueGetter: (params) => params?.row?.obra?.descricao
        },

        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 0.7,
            sortable: false,
            renderCell: (params) => <ActionButtonsTabela row={params?.row} getSolicitacoes={getSolicitacoes} />
        }
    ];

    // ============================= FUNÇÕES =============================

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const handleNavigate = () => {
        navigate('/main/nova-solicitacao');
    };

    const getSolicitacoes = async () => {
        try {
            const response = await api.getAllSolicitacao();
            setAllSolicitacao(response.data.solicitacoes);
        } catch (error) {
            toast.error(error.response.data.mensagem);
        }
    };

    useEffect(() => {
        getSolicitacoes();
    }, []);

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <MainCard title="Solicitações" sx={{ position: 'relative', marginTop: '10px' }}>
            <Box
                sx={{
                    display: 'flex',
                    gap: '1em',
                    position: 'absolute',
                    top: '80px',
                    right: '20px'
                }}
            >
                <Button variant="contained" size="medium" onClick={handleNavigate}>
                    Adicionar
                </Button>
                <ShoppingListReport />
            </Box>
            <Grid container spacing={gridSpacing}>
                <Box sx={{ marginTop: '40px', width: '100%' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Abertas" value="1" />
                                <Tab label="Encerradas" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            {' '}
                            <TableBox>
                                <TableDatagrid
                                    rows={allSolicitacao.filter((item) => item.status === 'Aberta')}
                                    columns={isMobile ? columnsMobi : columns}
                                    sx={isMobile ? { minWidth: '100%' } : { minWidth: '1400px' }}
                                    hideFooter={true}
                                    getRowHeight={() => 'auto'}
                                    onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                                />
                            </TableBox>
                        </TabPanel>
                        <TabPanel value="2">
                            {' '}
                            <TableBox>
                                <TableDatagrid
                                    rows={allSolicitacao.filter((item) => item.status === 'Encerrado')}
                                    columns={isMobile ? columnsMobi : columns}
                                    sx={isMobile ? { minWidth: '100%' } : { minWidth: '1400px' }}
                                    hideFooter={true}
                                    getRowHeight={() => 'auto'}
                                    onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                                />
                            </TableBox>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default SolicitacaoEstoque;
